// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let documentHeight = jQuery(document).height();

    if (100 < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    return false;
  });

  jQuery(".sp-btn").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
  });

  /* スムーススクロール */
  jQuery('a[href^="#"]').click(function() {
    let header = jQuery("#header").height();
    let speed = 300;
    let id = jQuery(this).attr("href");
    let target = jQuery("#" == id ? "html" : id);
    let position = jQuery(target).offset().top - header;
    if ("fixed" !== jQuery("#header").css("position")) {
      position = jQuery(target).offset().top;
    }
    if (0 > position) {
      position = 0;
    }
    jQuery("html, body").animate(
      {
        scrollTop: position
      },
      speed
    );
    return false;
  });

  /* 電話リンク */
  let ua = navigator.userAgent;
  if (ua.indexOf("iPhone") < 0 && ua.indexOf("Android") < 0) {
    jQuery('a[href^="tel:"]')
      .css("cursor", "default")
      .on("click", function(e) {
        e.preventDefault();
      });
  }
});

jQuery(function() {
  jQuery(".header-item a").each(function() {
    if (this.href == location.href) {
      jQuery(this)
        .parents("li")
        .addClass("current");
    }
    var path = location.pathname;
    if (path == "/") {
      jQuery(".header-item")
        .eq(0)
        .addClass("current");
    }
  });
});

jQuery(function() {
  jQuery("#acMenu .ac-ttl").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
    jQuery(this).toggleClass("active"); //追加部分
  });
});

$(function() {
  var slide_main = $(".slide").slick({
    asNavFor: ".slide-navigation",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true
  });
  var slide_sub = $(".slide-navigation").slick({
    asNavFor: ".slide",
    centerMode: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 400,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, //ブレイクポイントを指定
        settings: {
          slidesToShow: 2,
          centerMode: false
        }
      }
    ]
  });
  var open_window_Width = $(window).width();
  $(window).resize(function() {
    var width = $(window).width();
    if (open_window_Width != width) {
      slide_main.slick("setPosition");
      slide_sub.slick("setPosition");
    }
  });
});

$(function() {
  $.ajax({
    type: "GET",
    url:
      "https://graph.facebook.com/v5.0/17841447081825634?fields=name%2Cprofile_picture_url%2Cusername%2Cmedia.limit(6)%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Cthumbnail_url%7D&access_token=EAARI9ARmHxcBAK46gDtB3JrzWy0ZC1LXcWnoIQvknRHXLGtrYQpyWBARCM9FmFsYvY9KHVTMZCc0smsarxVistpyDVoiPg036ZASk4BlFvUA1QZBzRXOVA8ThWCe0ZBeBGrbtm8WMSI1QujyZCjkcmLgAEuv2ZBz9CxVG0wZCK17dBXDd44PvDfQ",
    dataType: "json",
    success: function(json) {
      // success
      var gallery_data = json["media"]["data"];
      var user = json["username"];
      var userimg = json["profile_picture_url"];
      console.log(user);
      let photos = '<a class="insta-ttl-contents" target="_blank" rel="noopener" href="https://www.instagram.com/' + user + '/"><p class="insta-logo"></p><div class="insta-ttl"><figure class="insta-img"><img src="' + userimg + '"></figure>' + user + '</div></a><ul class="instagram-list">';
      const photo_length = 6;
      //console.log(j[0]['permalink']);

      for (let i = 0; i < photo_length; i++) {
        if (gallery_data[i].thumbnail_url) {
          photos += '<div class="card"><a class="card-link" target="_blank" rel="noopener" href="' + gallery_data[i]["permalink"] + '"><img src="' + gallery_data[i].thumbnail_url + '"></a></div>';
        } else {
          photos += '<div class="card"><a class="card-link" target="_blank" rel="noopener" href="' + gallery_data[i]["permalink"] + '"><img src="' + gallery_data[i].media_url + '"></a></div>';
        }
      }
      photos += "</ul>";
      $("#instagram").append(photos);
    }
  });
});
